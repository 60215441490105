import { LOG_USER, LOGOUT_USER, UPDATE_USER } from '../actions/actions';
import initialState from "../initialState";

const userReducer = (state = initialState, action) => {
    switch (action.type) {



        case LOG_USER:
            return {
                ...state,
                isLogged: true,
                userUid: action.userUid,
                userToken: action.userToken,
                userIsAdmin: action.userIsAdmin,
                userEmail: action.userEmail,
                userLastName: action.userLastName,
                userFirstName: action.userFirstName,
                userAddressName: action.userAddressName,
                userAddress: action.userAddress,
                userAddressComp: action.userAddressComp,
                userTown: action.userTown,
                userPostalCode: action.userPostalCode,
                userCountry: action.userCountry,
                userPhone: action.userPhone,
                userAvatar: action.userAvatar,
                userServices: action.userServices,

            }
        case UPDATE_USER:
            return {
                ...state,

                userLastName: action.userLastName,
                userFirstName: action.userFirstName,
                userAddressName: action.userAddressName,
                userAddress: action.userAddress,
                userAddressComp: action.userAddressComp,
                userTown: action.userTown,
                userPostalCode: action.userPostalCode,
                userCountry: action.userCountry,
                userPhone: action.userPhone,
                userAvatar: action.userAvatar

            }

        case LOGOUT_USER:
            return {
                ...state,
                isLogged: false,
                userUid: "",
                userToken: "",
                userIsAdmin: "0",
                userEmail: "",
                userLastName: "",
                userFirstName: "",
                userAddressName: "",
                userAddress: "",
                userAddressComp: "",
                userTown: "",
                userPostalCode: "",
                userCountry: "",
                userPhone: "",
                userAvatar: "",
                userServices: "undefined",

            }
        default:
            return state;
    }
}



export default userReducer;