const CONFIG_APP = {


    APP_NAME: "US : URMET SERVICES",
    APP_VERS: "1.0.0",
    APP_DEBUG: true,



    //// CONFIG LANG + UI
    ROOT_HOST: 'https://portal.intraneturmet.fr', //"", // 
    ROOT_API: 'API',
    ROOT_ASSETS: 'ASSETS',
    ROOT_ASSETS_SERVICE: 'services',
    ROOT_ASSETS_AVATAR: 'avatars',

    API_KEY: "VGhpc0lzVSRBUEk=",


    ///   API  USER MY ACCOUNT
    API_LOGIN: 'signin.html',
    API_UPD_PROFIL: 'upduserinfo.html',
    //API_GET_PROFIL: 'getuserinfo.html',
    API_GET_PROFIL: 'getuserservices.html',

    // API ADMIN
    API_GET_ALLSERVICES: 'getallservices.html',
    API_ACTIVE_SERVICE: 'activeservice.html',
    API_SERVICE_PUBLIC: 'activeservicepublic.html',
    API_READ_SERVICE: 'readservice.html',
    API_CREATE_SERVICE: 'registerservice.html',
    API_UNREGISTER_SERVICE: 'unregisterservice.html',
    API_UPD_SERVICE: 'updservice.html',

    // API ADMIN USERS
    API_GET_ALLUSERS: 'getallusers.html',
    API_GET_USERINFO: 'getuserinfouid.html',
    API_UPD_USERINFO: 'upduserinfouid.html',
    API_ACTIVE_USER: 'activeuser.html',
    API_ADMIN_USER: 'activeuseradmin.html',
    API_DEL_USER: 'deluser.html',
    API_DEL_USER_GROUP: 'delusergroup.html',

    // API ADMIN GROUPS
    API_GET_GROUPS: 'getallgroups.html',
    API_GET_CURR_GROUPS: 'getcurrgroup.html',
    API_CREATE_GROUP: 'creategroup.html',
    API_UPD_GROUP: "updgroup.html",
    API_DEL_SERVICE_GROUP: 'delservicegroup.html',
    API_DEL_GROUP: 'delgroup.html',


}

export default CONFIG_APP;