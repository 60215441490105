export const msalConfig = {
    /* PROD */

    auth: {
        appId: '509d2abf-d1c9-4e77-95fc-e4095703ca4F',
        redirectUri: "https://portal.intraneturmet.fr",
        postLogoutRedirectUri: "https://portal.intraneturmet.fr",
        scopes: ['User.Read', 'profile', 'email'],
        authority: "https://login.microsoftonline.com/urmetfrance.onmicrosoft.com",
    }


    /// For debug LOCAL /  Serveur DEV
    /*
    auth: {
        appId: '509d2abf-d1c9-4e77-95fc-e4095703ca4F',
        redirectUri: "http://localhost:3000",
        postLogoutRedirectUri: "http://localhost:3000",
        scopes: ['User.Read', 'profile', 'email'],
        authority: "https://login.microsoftonline.com/urmetfrance.onmicrosoft.com"
    }
    */


    /// for debug Azure PERSO
    /*
       auth: {
           appId: '90af2855-9193-416f-8a21-ebaa0e6a88fc',
           redirectUri: "https://portal.yokis.fr",//"http://localhost:3000",
           postLogoutRedirectUri: "https://portal.yokis.fr",//"http://localhost:3000",
           scopes: ['User.Read', 'profile', 'email'],
           authority: "https://login.microsoftonline.com/marczemorioutlook.onmicrosoft.com"
       }
   */
};