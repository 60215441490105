export const LOG_USER = 'LOG_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = 'UPDATE_USER';


/**
 * Update User infos
 */
export function userLogin(
    isLoggedData,
    userUidData,
    userTokenData,
    userIsAdminData,
    userEmailData,
    userLastNameData,
    userFirstNameData,
    userAddressNameData,
    userAddressData,
    userAddressCompData,
    userTownData,
    userPostalCodeData,
    userCountryData,
    userPhoneData,
    userAvatarData,
    userServicesData,

) {
    return {
        type: LOG_USER,
        isLogged: isLoggedData,
        userUid: userUidData,
        userToken: userTokenData,
        userIsAdmin: userIsAdminData,
        userEmail: userEmailData,
        userLastName: userLastNameData,
        userFirstName: userFirstNameData,
        userAddressName: userAddressNameData,
        userAddress: userAddressData,
        userAddressComp: userAddressCompData,
        userTown: userTownData,
        userPostalCode: userPostalCodeData,
        userCountry: userCountryData,
        userPhone: userPhoneData,
        userAvatar: userAvatarData,
        userServices: userServicesData,
    };
}

export function userUpdateProfil(

    userLastNameData,
    userFirstNameData,
    userAddressNameData,
    userAddressData,
    userAddressCompData,
    userTownData,
    userPostalCodeData,
    userCountryData,
    userPhoneData,
    userAvatarData

) {
    return {
        type: UPDATE_USER,

        userLastName: userLastNameData,
        userFirstName: userFirstNameData,
        userAddressName: userAddressNameData,
        userAddress: userAddressData,
        userAddressComp: userAddressCompData,
        userTown: userTownData,
        userPostalCode: userPostalCodeData,
        userCountry: userCountryData,
        userPhone: userPhoneData,
        userAvatar: userAvatarData
    };
}

export function userLogOut() {
    return { type: LOGOUT_USER };
}