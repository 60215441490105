
export default {
    isLogged: false,
    userUid: "",
    userToken: "",
    userIsAdmin: "0",
    userEmail: "",
    userLastName: "",
    userFirstName: "",
    userAddressName: "",
    userAddress: "",
    userAddressComp: "",
    userTown: "",
    userPostalCode: "",
    userCountry: "",
    userPhone: "",
    userAvatar: "",
    userServices: "undefined"
}