const Menu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '/DashboardView',
        icon: 'icon-grid',
        translate: 'sidebar.nav.SINGLEVIEW',
        displayItem: true,
    },
    {
        name: 'Profil',
        path: '/ProfilView',
        icon: 'icon-note',
        translate: 'sidebar.nav.PROFIL',
        displayItem: true,

    },

    {
        heading: 'Administration',
        translate: 'sidebar.heading.ADMIN',
        isAdmin: 1,
        displayItem: true,
    },
    {
        name: 'Gestion des services',
        // path: '/ServiceView',
        icon: 'icon-notebook',
        translate: 'sidebar.nav.SERVICEVIEW',
        isAdmin: 1,
        displayItem: true,
        submenu: [{
            name: 'Liste des services',
            translate: 'sidebar.nav.SERVICELIST',
            path: '/ServiceView',
            displayItem: true,
        }
            /*
            , {
                name: 'Créer un service',
                translate: 'sidebar.nav.CREASERVICE',
                path: '/ServiceCreateView',
                displayItem: false,
            }, {
                name: 'Editer un service',
                translate: 'sidebar.nav.USERGROUP',
                path: '/ServiceEditView',
                displayItem: false,
            }
    */
        ]
    },
    {
        name: 'Utilisateurs',
        //  path: '/UerListView',
        icon: 'icon-user',
        translate: 'sidebar.nav.USERVIEW',
        isAdmin: 1,
        displayItem: true,
        submenu: [{
            name: 'Gestion des utilisateurs',
            translate: 'sidebar.nav.USERVIEWLIST',
            path: '/UsersView',
            displayItem: true,
        }, {
            name: 'Gestion des groupes',
            translate: 'sidebar.nav.USERGROUP',
            path: '/GroupsView',
            displayItem: true,
        }]
    },
    /*
    {
        name: 'Menu',
        icon: 'icon-speedometer',
        translate: 'sidebar.nav.MENU',
        label: { value: 1, color: 'info' },
        submenu: [{
            name: 'Submenu',
            translate: 'sidebar.nav.SUBMENU',
            path: 'submenu'
        }]
    }
    */
];

export default Menu;
