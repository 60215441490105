import React, { Component } from 'react';
import ContentWrapper from '../Layout/ContentWrapper';

import { Link } from 'react-router-dom';
import { Input, CustomInput, Button } from 'reactstrap';

import MSLogo from '../../assets/img/microsoft.svg';
import ServiceIcon from '../../assets/img/service.svg';

import { connect } from 'react-redux';
import { userLogin, userLogOut } from '../../store/actions/users.actions';

import axios from 'axios';
import CONFIG_APP from '../../config.app.js';

import Swal from 'sweetalert2';

import { msalConfig } from '../../azure/config-azure';
import { PublicClientApplication } from "@azure/msal-browser";



// Initialize the MSAL application object
const msalInstance = new PublicClientApplication({
    auth: {
        clientId: msalConfig.auth.appId,
        redirectUri: msalConfig.auth.redirectUri,
        authority: msalConfig.auth.authority,
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
});

msalInstance.handleRedirectPromise().then((tokenResponse) => {
    // Check if the tokenResponse is null
    if (tokenResponse === null) {
        console.log('standard loading page')
    } else {
        console.log('Auth redirect loading page')
        console.log('handleRedirectPromise : ' + JSON.stringify(tokenResponse))

        const userInfoResponse = msalInstance.getAllAccounts();
        let currAccount = msalInstance.getAccountByUsername(userInfoResponse[0].username);
        // console.log('currAccount >> ' + JSON.stringify(currAccount))
        // console.log('userInfoResponse >> ' + JSON.stringify(userInfoResponse))

        if (userInfoResponse === null) {
            console.log('  userInfoResponse : null')
        } else {
            console.log('  userInfoResponse : ' + JSON.stringify(userInfoResponse));
            localStorage.setItem('yumm.me', JSON.stringify(userInfoResponse))


        }

    }

    // If the tokenResponse !== null, then you are coming back from a successful authentication redirect. 
    // If the tokenResponse === null, you are not coming back from an auth redirect.
}).catch((error) => {
    console.log('handleRedirectPromise error : ' + error)
    // handle error, either in the library or coming back from the server
});

class LoginMS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLogged: false,
            errorMsg: "",
            userInfoResponse: "",
            loginPopup: false,
        }




        //this.publicClientApplication.handleRedirectPromise().then(this.handleResponse);

    }

    checkSessionStorage = async () => {
        let currSessionStorageData = "";
        let currSessionStorage = localStorage.getItem('yumm.me')
        if (currSessionStorage != null) {
            clearInterval(this.interval);


            currSessionStorageData = JSON.parse(currSessionStorage);



            let returnJSON = await axios({
                method: 'post',
                url: CONFIG_APP.ROOT_HOST + '/' + CONFIG_APP.ROOT_API + '/' + CONFIG_APP.API_LOGIN,
                headers: {
                    'X-API-Key': CONFIG_APP.API_KEY,
                },
                data: {
                    userUid: currSessionStorageData[0].localAccountId,
                    userEmail: currSessionStorageData[0].idTokenClaims.email,
                    userFirstName: currSessionStorageData[0].idTokenClaims.family_name,
                    userLastName: currSessionStorageData[0].idTokenClaims.given_name

                }
            }).catch((error) => {
                this.setState({
                    hasAPIresponse: true,
                    isLoading: false,
                });

                if (error) {
                    console.log("error.response.status : " + JSON.stringify(error));

                }


                this.setState({
                    isLogged: false,
                    errorMsg: "Code :  " + error//.response.status
                })



                //  this.props.disconnectUser();

            });

            if (returnJSON != undefined) {
                if (returnJSON.status === 200) {
                    /// le serveur à répondu
                    console.log(JSON.stringify(returnJSON.data))


                    if (returnJSON.data.status == "error") {
                        switch (returnJSON.data.errorCode) {
                            default:
                                this.setState({
                                    isLogged: false,
                                    errorMsg: "Erreur > " + returnJSON.data.errorCode
                                })
                                break;
                        }
                    }




                    if (returnJSON.data.status == "SIGNIN_OK") {
                        /// utilisateur trouvé

                        if (returnJSON.data.userIsActive == "1") {

                            this.setState({

                                isLoading: false,
                                isLogged: true

                            })


                            /// 



                            /// Update Store

                            this.props.userLogin(
                                true,
                                returnJSON.data.uuid,
                                returnJSON.data.token,
                                returnJSON.data.userIsAdmin,
                                returnJSON.data.userEmail,
                                returnJSON.data.userLastName,
                                returnJSON.data.userFirstName,
                                returnJSON.data.userAddressName,
                                returnJSON.data.userAddress,
                                returnJSON.data.userAddressComp,
                                returnJSON.data.userTown,
                                returnJSON.data.userPostalCode,
                                returnJSON.data.userCountry,
                                returnJSON.data.userPhone,
                                returnJSON.data.userAvatar,
                                returnJSON.data.services
                            )
                            this.props.history.push('/DashboardView');
                        } else {
                            this.setState({

                                isLoading: false,
                                isLogged: false

                            })

                            Swal.fire({
                                position: 'top',
                                icon: 'error',
                                title: 'Compte désactivé sur le portail',
                                text: "Veuillez contacter l'administreur.",
                                showConfirmButton: false,
                                timer: 3000
                            });
                        }

                    }








                } else {
                    /// reponse reçue mais pas 200
                    this.setState({
                        isLogged: false,
                        errorMsg: "Erreur > " + returnJSON.status + " : " + returnJSON.data.errorCode
                    })


                    //this.props.disconnectUser();

                }
            } else {
                console.log("ERROR API CALL : undefined")
                this.setState({
                    isLogged: false,
                    errorMsg: "Erreur > " + JSON.stringify(returnJSON)
                })

                // this.props.disconnectUser();
            }

        } else {
            console.log('currSessionStorage localStorage == null')
        }


    }


    componentDidMount() {
        console.log('mount LOGIN at ' + Date.now())

        if (this.props.isLogged) {
            this.props.history.push('/DashboardView')
        }

        this.interval = setInterval(this.checkSessionStorage, 500);


    }

    loginRedirect = async () => {
        try {
            // Login via Redirect  
            /*
            this.PublicClientApplication.handleRedirectCallback((error, response) => {
                // if error is not null, something went wrong
                console.log("handleRedirectCallback err " + error);
                // if not, response is a successful login response
                console.log("handleRedirectCallback resp " + response);
            });
            */


            await msalInstance.loginRedirect(
                {
                    scopes: msalConfig.auth.scopes,
                    prompt: "select_account"   /// valeurs possibles : 'login', 'select_account', 'consent' and 'none'
                });
        }
        catch (err) {
            this.setState({
                isLogged: false,
                user: {},
                error: err
            });
        }
    }


    loginPopup = async () => {



        try {
            // Login via popup  




            await msalInstance.loginPopup(
                {
                    scopes: msalConfig.auth.scopes,
                    prompt: "select_account"   /// valeurs possibles : 'login', 'select_account', 'consent' and 'none'
                });

            var userInfoResponse = msalInstance.getAllAccounts();



            var currAccount = msalInstance.getAccountByUsername(userInfoResponse[0].username);

            var tokenResponse = await msalInstance.acquireTokenSilent({
                scopes: msalConfig.auth.scopes,
                account: currAccount
            });




            /////////////////////////////////////////////
            ///////////////// AXIOS /////////////////////
            /// VERIF DATABASE USER EXISTS / PORTALYOKIS
            /////////////////////////////////////////////
            /////////////////////////////////////////////
            console.log('URL Called : ' + CONFIG_APP.ROOT_HOST + '/' + CONFIG_APP.ROOT_API + '/' + CONFIG_APP.API_LOGIN)

            let returnJSON = await axios({
                method: 'post',
                url: CONFIG_APP.ROOT_HOST + '/' + CONFIG_APP.ROOT_API + '/' + CONFIG_APP.API_LOGIN,
                headers: {
                    'X-API-Key': CONFIG_APP.API_KEY,
                },
                data: {
                    userUid: userInfoResponse[0].localAccountId,
                    userEmail: userInfoResponse[0].idTokenClaims.email,
                    userFirstName: userInfoResponse[0].idTokenClaims.family_name,
                    userLastName: userInfoResponse[0].idTokenClaims.given_name

                }
            }).catch((error) => {
                this.setState({
                    hasAPIresponse: true,
                    isLoading: false,
                });

                console.log("error.response.status : " + error.response.status);
                console.log(error.response.data.codes);

                this.setState({
                    isLogged: false,
                    errorMsg: "Code :  " + error.response.status
                })



                //  this.props.disconnectUser();

            });

            if (returnJSON != undefined) {
                if (returnJSON.status === 200) {
                    /// le serveur à répondu
                    console.log(JSON.stringify(returnJSON.data))
                    clearInterval(this.interval);

                    if (returnJSON.data.status == "error") {
                        switch (returnJSON.data.errorCode) {
                            default:
                                this.setState({
                                    isLogged: false,
                                    errorMsg: "Erreur > " + returnJSON.data.errorCode
                                })
                                break;
                        }
                    }




                    if (returnJSON.data.status == "SIGNIN_OK") {
                        /// utilisateur trouvé
                        this.setState({

                            isLoading: false,
                            isLogged: true

                        })



                        /// Update Store

                        this.props.userLogin(
                            true,
                            returnJSON.data.uuid,
                            returnJSON.data.token,
                            returnJSON.data.userIsAdmin,
                            returnJSON.data.userEmail,
                            returnJSON.data.userLastName,
                            returnJSON.data.userFirstName,
                            returnJSON.data.userAddressName,
                            returnJSON.data.userAddress,
                            returnJSON.data.userAddressComp,
                            returnJSON.data.userTown,
                            returnJSON.data.userPostalCode,
                            returnJSON.data.userCountry,
                            returnJSON.data.userPhone,
                            returnJSON.data.userAvatar,
                            returnJSON.data.services
                        )
                        this.props.history.push('/DashboardView');


                    }








                } else {
                    /// reponse reçue mais pas 200
                    this.setState({
                        isLogged: false,
                        errorMsg: "Erreur > " + returnJSON.status + " : " + returnJSON.data.errorCode
                    })


                    //this.props.disconnectUser();

                }
            } else {
                console.log("ERROR API CALL : undefined")
                this.setState({
                    isLogged: false,
                    errorMsg: "Erreur > " + returnJSON.status + " : " + returnJSON.data.errorCode
                })

                // this.props.disconnectUser();
            }

        }
        catch (err) {
            this.setState({
                isLogged: false,
                userInfo: {},
                error: err
            });
        }
    }


    callMSGraph = async (endpoint, token) => {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        var userData;
        fetch(endpoint, options)
            .then((response) => response.json())
            .then(
                (result) => {
                    console.log(result)
                }
            )
            .catch(error => console.log(error));
    }




    logOutPopup = async () => {
        localStorage.clear();
        console.log('clear storage');
        this.props.userLogOut();


        var logoutRequest = {
            account: this.publicClientApplication.getAccountByHomeId(this.state.homeAccountId),
            mainWindowRedirectUri: msalConfig.auth.postLogoutRedirectUri
        }
        await this.publicClientApplication.logoutPopup(logoutRequest);


    }


    render() {
        return (
            <ContentWrapper>
                <div className="block-center mt-4 wd-xl">
                    <div className="card card-flat">
                        <div className="card-header text-center  " style={{ backgroundColor: "#000000" }}>

                            <img className="block-center  " src={ServiceIcon} alt="Logo" width="95%" />


                        </div>
                        <div className="card-body">
                            <p className="text-center py-2">Bienvenue sur le Portail de Services<br />
                                URMET YOKIS.</p>

                            {this.state.errorMsg != "" ? (
                                <div className="text-warning"> Une erreure est survenue :<br />
                                    {this.state.errorMsg}</div>

                            ) : (null)}


                            {!this.props.isLogged ? (
                                <p className="pt-3 text-center">
                                    <img src={MSLogo} alt="Connexion via MS365" style={{ marginBottom: 10 }} onClick={() => this.loginPopup()} />
                                    {this.state.loginPopup ? (
                                        <Button color="primary" onClick={() => this.loginPopup()} block >
                                            Se connecter
                                        </Button>
                                    ) : (
                                        <Button color="primary" onClick={() => this.loginRedirect()} block >
                                            Se connecter
                                        </Button>
                                    )}



                                </p>

                            ) : (
                                <div>
                                    <p> Bonjour {this.props.userFirstName}</p>
                                    <p>@ : {this.props.userEmail}</p>
                                    <p>Token : {this.props.userToken}</p>
                                    <p>UUID : {this.props.userUid}</p>


                                    <Button color="secondary" onClick={() => this.logOutPopup()} block>Se déconnecter</Button>


                                </div>
                            )}


                        </div>
                    </div>
                    <div className="p-3 text-center">
                        <span className="mr-2">&copy;</span>

                        <span>URMET SERVICES</span>

                    </div>
                </div >

            </ContentWrapper>
        );
    }
}



const mapStoreToProps = (store) => {
    return {



        isLogged: store.user.isLogged,
        userUid: store.user.userUid,
        userToken: store.user.userToken,
        userEmail: store.user.userEmail,
        userLastName: store.user.userLastName,
        userFirstName: store.user.userFirstName,
        userAddressName: store.user.userAddressName,
        userAddress: store.user.userAddress,
        userAddressComp: store.user.userAddressComp,
        userTown: store.user.userTown,
        userPostalCode: store.user.userPostalCode,
        userCountry: store.user.userCountry,
        userPhone: store.user.userPhone,
        userAvatar: store.user.userAvatar,
        userServices: store.user.userServices
    }
}
const mapDispatchtoProps = {
    userLogin,
    userLogOut
}

export default connect(mapStoreToProps, mapDispatchtoProps)(LoginMS);
