import React, { Component } from 'react';


import { Link } from 'react-router-dom';


import { connect } from 'react-redux';
import { userLogOut } from '../../store/actions/users.actions';

import CONFIG_APP from '../../config.app.js';

import { msalConfig } from '../../azure/config-azure';
import { PublicClientApplication } from "@azure/msal-browser";



class LogoutButtonMS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLogged: false,
            errorMsg: "",
        }

        // Initialize the MSAL application object
        this.publicClientApplication = new PublicClientApplication({
            auth: {
                clientId: msalConfig.auth.appId,
                redirectUri: msalConfig.auth.redirectUri,
                authority: msalConfig.auth.authority,
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: true
            }
        });
    }

    componentDidMount() {

    }




    logOutPopup = async () => {
        localStorage.clear();
        console.log('clear storage');
        this.props.userLogOut();
        sessionStorage.clear();

        var logoutRequest = {
            account: this.publicClientApplication.getAccountByHomeId(this.state.homeAccountId),
            mainWindowRedirectUri: msalConfig.auth.postLogoutRedirectUri
        }
        await this.publicClientApplication.logoutPopup(logoutRequest);



    }


    render() {
        return (
            <div>
                <em className="fas fa-power-off" onClick={() => this.logOutPopup()} ></em>
            </div>
        )
    }
}



const mapStoreToProps = (store) => {
    return {


        isLogged: store.user.isLogged,
        userUid: store.user.userUid,
        userToken: store.user.userToken,
        userEmail: store.user.userEmail,
        userLastName: store.user.userLastName,
        userFirstName: store.user.userFirstName,
        userAddress: store.user.userAddress,
        userAddressComp: store.user.userAddressComp,
        userTown: store.user.userTown,
        userPostalCode: store.user.userPostalCode,
        userCountry: store.user.userCountry,
        userPhone: store.user.userPhone,
        userAvatar: store.user.userAvatar,
        userServices: store.user.userServices
    }
}
const mapDispatchtoProps = {

    userLogOut
}

export default connect(mapStoreToProps, mapDispatchtoProps)(LogoutButtonMS);