import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

import Avatar from '@material-ui/core/Avatar';
import { withNamespaces, Trans } from 'react-i18next';

import CONFIG_APP from '../../config.app.js';
import { connect } from 'react-redux';

class SidebarUserBlock extends Component {

    state = {
        showUserBlock: false
    }

    componentWillReceiveProps(newProps) {
        if (newProps.showUserBlock !== this.props.showUserBlock) {
            this.setState({ showUserBlock: newProps.showUserBlock })
        }
    }

    render() {
        return (
            <Collapse id="user-block" isOpen={this.state.showUserBlock}>
                <div>
                    <div style={{ marginTop: '20px' }}  >
                        {/* User picture */}
                        <div style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 1 }}>{
                            this.props.userAvatar != "" ?
                                (
                                    <div ><Avatar
                                        alt={this.props.userFirstName + " " + this.props.userLastName}
                                        src={CONFIG_APP.ROOT_HOST + '/' + CONFIG_APP.ROOT_ASSETS + '/' + CONFIG_APP.ROOT_ASSETS_AVATAR + '/' + this.props.userAvatar}

                                    /></div>
                                ) :
                                (
                                    <div  ><Avatar  >{this.props.userFirstName.substr(0, 1) + this.props.userLastName.substr(0, 1)}</Avatar></div>
                                )
                        }</div>
                        {/* Name and Job */}
                        <div style={{ textAlign: 'center' }}>
                            <span className="user-block-name"><Trans i18nKey='dashboard.WELCOME_USER'    ></Trans> {this.props.userFirstName}</span>
                        </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

SidebarUserBlock.propTypes = {
    showUserBlock: PropTypes.bool,



};

const mapStateToProps = store => ({
    showUserBlock: store.settings.showUserBlock,

    isLogged: store.user.isLogged,
    userUid: store.user.userUid,
    userToken: store.user.userToken,
    userEmail: store.user.userEmail,
    userLastName: store.user.userLastName,
    userFirstName: store.user.userFirstName,
    userAddress: store.user.userAddress,
    userAddressComp: store.user.userAddressComp,
    userTown: store.user.userTown,
    userPostalCode: store.user.userPostalCode,
    userCountry: store.user.userCountry,
    userPhone: store.user.userPhone,
    userAvatar: store.user.userAvatar,
    userServices: store.user.userServices

})

export default connect(
    mapStateToProps
)(SidebarUserBlock);